<template>
  <v-app id="inspire">
    <!-- <SystemBar /> -->

    <AppBar />
    

    <Profile />
  </v-app>
</template>

<script>
import AppBar from "../baseComponents/AppBar.vue";
// import LabelManagement from "../components/SettingChat/LabelManagement.vue"
import Profile from "../components/User/Profile.vue";

export default {
      name: 'profile',
  data: () => ({}),
  components: {
    AppBar,
    Profile
},
};
</script>
<style lang="scss" scoped>
#inspire {
  background: #f9fafc;
  .customer {
    background: #ffffff;
    padding: 24px;
    margin: 30px;
    margin-top: 96px;
    border-radius: 10px;
  }
}
</style>