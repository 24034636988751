<template>
  <div class="global-container">
    <div class="profile-container">
      <div class="avatar">
        <div class="img-info">
          <img :src="user.avatar ? user.avatar : '/logo.png'" alt="User Avatar" />
          <div class="d-flex justify-center">
            <h2 class="text-center mx-2">{{ user.firstName }} {{ user.lastName }}</h2>
            <div class="mx-2 my-auto" style="font-size: 24px;color:#65696e">({{user.username}})</div>
            <v-icon color="green">mdi-check-circle</v-icon>
          </div>
        </div>
      </div>
      <div class="user-info">
        <h3>Thông tin chung</h3> 
      </div>
      <div class="permissions">
        <v-row>
            <v-col cols="12" md="4"><strong>Họ và tên:</strong> {{ user.firstName }} {{ user.lastName }}</v-col>
            <v-col cols="12" md="4"><strong>Tên đăng nhập:</strong> {{ user.username }}</v-col>
            <v-col cols="12" md="4"><strong>Vị trí:</strong> {{ user.position ? user.position : '--' }}</v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4"><strong>Ngày sinh:</strong> {{ user.dateOfBirth ? user.dateOfBirth : '--' }}</v-col>
            <v-col cols="12" md="4"><strong>Giới tính:</strong> {{ user.gender ? user.gender : '--' }}</v-col>
            <v-col cols="12" md="4"><strong>Email:</strong> {{ user.email ? user.email : '--' }}</v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4"><strong>Số điện thoại:</strong> {{ user.phone ? user.phone : '--' }}</v-col>
            <v-col cols="12" md="4"><strong>Số máy lẻ:</strong> {{ user.ext ? user.ext : '--' }}</v-col>
            <v-col cols="12" md="4"><strong>Nhóm quyền:</strong> {{user.role && user.role.name ? user.role.name : 'Chưa xác định'}}</v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import TokenService from '../../helpers/token';
const user = TokenService.getUser();
export default {
  data() {
    return {
        user,
    };
  },
};
</script>

<style lang="scss" scoped>
.global-container {
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.profile-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%; /* Ensure the container takes the full width */
}

.avatar {
  width: 100%;
  height: 200px;
  background: rgb(173, 232, 234);
  background: linear-gradient(
    148deg,
    rgba(173, 232, 234, 1) 4%,
    rgba(159, 161, 230, 1) 86%,
    rgba(233, 148, 169, 0.9850315126050421) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.avatar {
  .img-info {
      position: absolute;
      bottom: -50%;
      text-align: center;
    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
      border: 8px solid rgb(85, 166, 220);
      background-color: #fff;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }
}

.user-info {
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    margin-top: 100px;
}
.permissions {
  padding: 20px;
  box-sizing: border-box;
}

.user-info h2 {
  margin-bottom: 10px;
}

.permissions h3 {
  margin-bottom: 10px;
}

.permissions ul {
  list-style-type: none;
  padding: 0;
}

.permissions li {
  margin-bottom: 5px;
}
</style>
